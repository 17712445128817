import React, { useState, useEffect, useCallback } from "react";
import NoteContext from "./NoteContext";
import { io } from "socket.io-client"; // if using socket.io-client
import apiService from "../../Commonapi/AppServer";
import { toast } from "react-toastify";

const NoteState = (props) => {
    // Initialize WebSocket state
    const [socket, setSocket] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const token = localStorage.getItem('token')
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        // const newSocket = io("https://tsdevadmin.testenvapp.com", {
        //   transports: ["websocket"],
        //   auth: { token: localStorage.getItem('token') }
        // });
        // setSocket(newSocket);
    
        // return () => {
        //   newSocket.disconnect();
        // };
        handleGetLoginUser();
      }, []);

    // Define your state
    const state = {
        messages,
        socket,
        currentUser,
        setCurrentUser
    };

    const handleGetLoginUser = useCallback(async () => {
     if(token && (!currentUser || currentUser === null)){ try {
          const headers = {
              "Content-Type": "application/json",
              Authorization: token,
          };
          const response = await apiService(`${BASE_URL}/user`, "GET", {}, headers);
          console.log(response)
    
          if (response.status === true) {
           setCurrentUser(response.data)
          }else{
            if(token){
               toast.error("Session expired")
               localStorage.removeItem('token')
               setCurrentUser(null)
            }else{
                setCurrentUser(null)
            }
          }
      } catch (error) {
          console.log(error.message)
      }}
    }, [token]);

    return (
        <NoteContext.Provider value={state}>
            {props.children}
        </NoteContext.Provider>
    );
};

export default NoteState;
