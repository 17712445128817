import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import image from "../../src/assets/images.png";
import image2 from "../../src/assets/logo.png";
import mic from "../../src/assets/mic.png";
import { Link } from "react-router-dom";
import logo from "../assets/Svg/logo.svg";
import NoteContext from "./Context/notes/NoteContext";
import apiService from "./Commonapi/AppServer";
import { toast } from "react-toastify";

// const socket = io.connect("http://localhost:4000");

const Chat = () => {
  
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [language, setLanguage] = useState("en");
  const location = useLocation();
  const {id} = useParams();
  const [chapter, setChapter] = useState("");
  const [socket, setSocket] = useState('');
  const [messages, setMessages] = useState([]);
  const [allChats, setAllChats] = useState([]);
  const [input, setInput] = useState("");
  const ref = useRef();
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate= useNavigate()
  const { setCurrentUser , currentUser } = useContext(NoteContext);
  const token = localStorage.getItem('token')

  
  const [isListening, setIsListening] = useState(false);
  const shouldFetch = useRef(true);

  useEffect(()=>{
   if(token && currentUser){ const socket = io(process.env.REACT_APP_BASE_URL, {
      transports: ["websocket"],
      auth: { token: localStorage.getItem('token') }
    });
    setSocket(socket)}
  }, [token , currentUser])



  const showMenu = () => setMenuVisible(true); // Show menu on hover
  const hideMenu = () => setMenuVisible(false); // Hide menu when hover ends

  const handleLogout = () => {
    localStorage.removeItem("token");
    setCurrentUser(null)
  };
  function formatQuizOrReturnNormal(response) {
    // Patterns for detection
    const quizQuestionPattern = /^\d+\.\s.*$/m; // Detects "1. Question" format
    const quizOptionPattern = /^[A-D]\.\s.*$/m; // Detects "A. Option" format
    const sentencePattern = /^\d+\.\s".*"\s\(.+\)$/m; // Detects sentence structure like "Ech huelen eng _____." (I am taking a _____.)

    // Check for standard quiz format
    if (
      quizQuestionPattern.test(response) &&
      quizOptionPattern.test(response)
    ) {
      return `<pre>${response}</pre>`;
    }

    // Check for sentence-based quiz format
    if (quizQuestionPattern.test(response) && sentencePattern.test(response)) {
      return `<pre>${response}</pre>`;
    }

    // If no specific format detected, return as is
    return response;
  }

  const handleSend = () => {
    if(id){
      let text = ``;
      if (chapter !== "") {
        text = `from ${chapter} file.`;
      }
      if (input.trim()) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: `<p>${input}</p>`,
            sendBy: "You",
            createdAt: new Date().toLocaleTimeString(),
          },
        ]);
        socket.emit("ask-question", {message :input , chatId : id});
        setInput("");
    }else{
      
    }
    }
  };

  useEffect(() => {
   if(socket){
     const handleResponse = (data) => {
      const resp = formatQuizOrReturnNormal(data);
      console.log(data);
      console.log(resp);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: resp,
          sendBy: "AI",
          createdAt: new Date().toLocaleTimeString(),
        },
      ]);
    };
  
    socket.on("connect", () => {
      console.log("Socket connected with ID:", socket.id);
      if (shouldFetch.current) {
        shouldFetch.current = false; // Ensure the message is only sent once
      }
    });
  
    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });
  
    // Add listener for "question-response"
    socket.on("question-response", handleResponse);

    socket.on('chat-created', (newChat) => {
      navigate(`/chat/${newChat._id}`);
      setMessages([])
      setAllChats(
        prevChats => [newChat,...prevChats]
      )
    });
  
    // Cleanup listener on component unmount
    return () => {
      socket.off("question-response", handleResponse);
      socket.off("connect");
      socket.off("chat-created");
      socket.off("disconnect");
    }}
  }, [socket , currentUser , token]);
  

  // useEffect(() => {
  //     socket.on("connect", () => {
  //     console.log("Socket connected with ID:", socket.id);
  //     socket.emit("ask-question","hello");

  //   });
  
  //   socket.on("disconnect", () => {
  //     console.log("Socket disconnected.");
  //   });
  

  //   socket.on("question-response", (data) => {
  //     const resp = formatQuizOrReturnNormal(data);
  //     console.log(data);
  //     console.log(resp);
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       {
  //         text: resp,
  //         user: "AI",
  //         timestamp: new Date().toLocaleTimeString(),
  //       },
  //     ]);
  //   });

  //   return () => {
  //     socket.off("question-response");
  //   };
  // }, []);

const handleGetAllMessages = useCallback(async (id) => {
  setMessages([])
  try {
      const headers = {
          "Content-Type": "application/json",
          Authorization: token,
      };
      const response = await apiService(`${BASE_URL}/message/${id}`, "GET", {}, headers);
      console.log(response)

      if (response.status === true) {
        if(response?.data?.length === 0) {
          socket.emit("ask-question", {message : "system message : hello"  , chatId : id})
        }
          setMessages(response.data);
          toast.success(response.message);
      } else {
          toast.error(response.message);
      }
  } catch (error) {
      toast.error(error.response?.message || "Error fetching messages");
  }
}, [token]); // Dependencies needed inside handleGetAllMessages

const handleConversations = useCallback(async () => {
  try {
      const headers = {
          "Content-Type": "application/json",
          Authorization: token,
      };
      const response = await apiService(`${BASE_URL}/chat`, "GET", {}, headers);
      console.log(response)

      if (response.status === true) {
        setAllChats(response.data);
      } else {
          toast.error(response.message);
      }
  } catch (error) {
      toast.error(error.response?.message || "Error fetching messages");
  }
}, [token]);

useEffect(() => {
  if (id) {
  //  if(shouldFetch.current){ 
    handleGetAllMessages(id)
    // shouldFetch.current = false; // Ensure the message is only sent once
  // }
  }
}, [id]); // Dependency array to trigger on path change

useEffect(()=>{
  handleConversations()
},[])

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [messages]);

  const handleStartListening = () => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.continuous = false;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript;
      setInput((prev) => prev + " " + speechResult);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
  };

  const handleNewChat = ()=>{
    socket.emit('new-chat', '');
  }

  return (
    <div className="flex w-full h-screen">
      <div className="w-2/12 p-3 pt-0 overflow-y-auto">
       <div className="py-3 w-full sticky top-0 bg-white"> <button className="font-medium top-0   sticky w-full  bg-[#00A3E0] px-3 py-2 text-white rounded-xl text-xl" onClick={handleNewChat}> Start new chat</button></div>
        <div>
          {allChats?.map((item, index) => (
            <div key={index} className="my-3">
              <button onClick={()=>navigate(`/chat/${item?._id}`)} className="font-medium w-full  bg-[#e7f0f3] px-3 py-2 text-black rounded-xl text-xls">{`Chat ${index + 1}`}
              <br /> <small>{new Date(item?.createdAt).toLocaleString()}</small>
              </button>
              {/* {item?.questions?.map((qa, idx) => (
                <div key={idx} className="text-secondary mb-3 font-semibold text-sm truncate">
                  {qa}
                </div>
              ))} */}
            </div>
          ))}
        </div>
      </div>
      <div className="w-10/12 h-screen ">
      <div className="flex h-[10%] justify-between items-center p-3">
           <Link to='/'> <img src={logo} alt="" className="max-w-[100px]"/></Link>
            <div className="flex gap-3 items-center">
              <div>
                <select
                  className="form-select"
                  value={chapter}
                  onChange={(e) => setChapter(e.target.value)}
                >
                  <option value="">Select Chapter</option>
                  <option value={"Supermaket "}>Supermaket</option>
                </select>
              </div>
              <div>
                <select
                  className="form-select"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="hi">Hindi</option>
                  <option value="fr">French</option>
                  <option value="es">Spanish</option>
                  <option value="it">Italian</option>
                </select>
              </div>

              <div
      className="relative"
      onMouseEnter={showMenu} // Show menu on hover
      onMouseLeave={hideMenu} // Hide menu on mouse leave
    >
      {/* Profile Image */}
      <div className="border rounded-full p-1 overflow-hidden cursor-pointer">
        <img
          src={image}
          className="w-8 h-8 object-cover rounded-full"
          alt="profile"
        />
      </div>

      {/* Dropdown Menu */}
      {menuVisible && (
        <div className="absolute right-0  w-40 bg-white  rounded-lg shadow-lg">
          {/* <Link to="/">
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100" >
            Profile
          </button>
          </Link> */}
        
          <button
            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}
    </div>

            </div>
          </div>
        <div className=" px-2 pt-1 h-[80%]">
          <div className="h-full border-t-2 border-l-2 border-r-0 border-b-0 overflow-y-auto p-3" ref={ref}>
            {messages?.map((message, index) => (
              <div
                key={index}
                className={`mb-2 flex flex-col gap-1 ${message.sendBy === "You" ? "text-right" : ""}`}
              >
                <div
                  className={`flex gap-2 items-end ${message.sendBy === "You" ? "flex-row-reverse" : "flex-row"}`}
                >
                  <strong>{message.sendBy}</strong> <small>{message.createdAt}</small>
                </div>
                <div
                  className={`messageBox ${message.sendBy === "You" ? "ml-auto" : ""}`}
                  dangerouslySetInnerHTML={{ __html: message.message }}
                ></div>
              </div>
            ))}
            {messages?.length === 0 && (
              <div className="w-full h-full flex justify-center items-center">
                <div className="rounded-full p-1 w-auto">
                  <img src={image2} className="w-36" alt="logo" />
                </div>
              </div>
            )}
          </div>
          
        
        </div>
        <div className="py-2 h-[10%]">
           {id ? <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSend();
              }}
            >
              <div className="flex px-4  ">
                <div className="w-9/12 flex items-center">
                  <img
                    src={mic}
                    width={"25px"}
                    height={"25px"}
                    className="cursor-pointer"
                    onClick={handleStartListening}
                    alt="microphone"
                  />
                  <input
                    type="text"
                    placeholder="Type a message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className="border-t-2 border-b-2 border-l-2  border-r-0 p-2 w-full"
                  />
                </div>
                <div className="w-3/12">
                  <button
                    type="button"
                    onClick={handleSend}
                    className="w-full bg-blue-500 text-white py-[.6rem] rounded-xl px-4 "
                  >
                    Send
                  </button>
                </div>
              </div>
            </form> :
            <div className="w-full flex justify-center bg-white"> <button className="font-medium  w-2/3  bg-[#00A3E0] px-3 py-2 text-white rounded-xl text-xl" onClick={handleNewChat}> Start new chat</button></div> 
            }
          </div>
      </div>
    </div>
  );
};

export default Chat;



// import React, { useContext } from 'react'
// import NoteContext from './Context/notes/NoteContext'
// const Chat = () => {
// const a =useContext(NoteContext)
//   return (
//     <div>
//         <div class="grid grid-cols-12 gap-4">
//   <div className='col-span-3'>
//     <div className='py-4 flex   border border-b-0 border-l-2 border-t-0 border-r-0 justify-center'>
//     New Chat here

//     </div>
//   </div>
//   <div className='col-span-9'>
// <div className='py-6 '>
// EloElo
//   {a.notes.map((note) => (
//     <div key={note.id} className='py-2 px-4 border border-b-0 border-l-0 border-t-0 border-r-0'>
//       {note.content}
//     </div>
//   ))}
// </div>
//   </div>
// </div>
//     </div>
//   )
// }

// export default Chat