import React, { useState } from 'react';
import axios from 'axios';
import logo from "../../assets/Svg/logo.svg";
import { Link } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // For loading spinner
import apiService from '../Commonapi/AppServer';
const RequestPasswordReset = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleButtonClick = async () => {
    // Validate the email format
    if (!email) {
      setError("Please enter your email.");
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    const url = `${BASE_URL}/auth/sendForgotLink`;
    const payload = { email };
    const headers = { "Content-Type": "application/json" };

    const response = await apiService(url, "POST", payload, headers);

    setLoading(false);

    if (response.status === true) {
        toast.success(response.message);
      setError('');
      setTimeout(() => {
        setSuccess('');
      }, 3000);
      setEmail('');
    } else {
      setError(response.error?.message || "Failed to send reset link. Please try again.");
    }
  };
  // const handleButtonClick = async () => {
  //   // Validate the email format
  //   if (!email) {
  //     setError("Please enter your email.");
  //     return;
  //   }
  //   if (!validateEmail(email)) {
  //     setError("Please enter a valid email address.");
  //     return;
  //   }
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/auth/sendForgotLink`,
  //       { email },
  //       { headers: { "Content-Type": "application/json" } }
  //     );

  //     setLoading(false);
      
  //     if (response.status === 200) {
  //       setSuccess("A password reset link has been sent to your email.");
  //       setError('');
  //       setTimeout(() => {
  //         setSuccess('');
  //       }, 3000);
  //       setEmail('');
  //     } else {
  //       setError(response.data.message || "Failed to send reset link. Please try again.");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     setError("An error occurred. Please try again.");
  //   }
  // };

  return (
    <div className="min-h-screen w-full flex flex-col bg-white">
      {/* Header with logo and close button */}
      <div className="fixed flex items-center w-full px-6 py-4 justify-between border-b-2">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-12 xs:w-28 sm:w-32" />
        </Link>
        <Link to="/">
          <RxCross1 className="text-2xl text-gray-700 hover:text-gray-900 transition duration-200" />
        </Link>
      </div>
      <ToastContainer />
      {/* Main Content */}
      <div className="flex flex-grow  xs:justify-normal xs:mt-12 flex-col w-full items-center justify-center pt-20 px-4">
        <div className="max-w-[600px] xs:p-2  rounded-lg p-8">
          <h1 className="text-[34px] lexend-bold text-gray-900 mb-4">Forgot Password</h1>
          <p className="text-[15px] lexend-normal text-gray-600 mb-6">
            Enter the email associated with your account and we'll send an email with instructions to reset your password.
          </p>

          {/* Input Field */}
          <div className="mb-4">
            <label htmlFor="email" className="text-[15px]  pl-2 lexend-normal text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-2 pl-2 py-2 text-gray-700 placeholder-gray-500 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-600 focus:outline-none transition duration-200"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            onClick={handleButtonClick}
            className={`w-full flex items-center justify-center text-white rounded-lg px-4 py-2 transition duration-300 ${
              loading
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-[#00A3E0] "
            }`}
            disabled={loading}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="animate-spin mr-2" />
            ) : (
              "Submit"
            )}
          </button>
          <Link to ="/signup">
<p className='flex gap-3 text-[16px] lexend-medium text-center w-full items-center mt-6 justify-center'><IoArrowBackSharp/>  Back to  Sign up</p>
           </Link>
          {/* Error and Success Messages */}
          {error && <div className="text-red-500 mt-4 text-[15px] lexend-medium">{error}</div>}
          {success && <div className="text-green-500 mt-4 text-[15px] lexend-medium">{success}</div>}
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordReset;




// import React, { useState } from 'react';
// import axios from 'axios'; // Import axios
// import logo from "../../asstes/icons/logo.svg";
// import { Link } from "react-router-dom";
// import { RxCross1 } from "react-icons/rx";

// const OTPVerification = () => {
//   const [otp, setOtp] = useState(['', '', '', '', '', '']);
//   const [showotp, setShowotp] = useState(false);
//   const [emaildiv, setEmaildiv] = useState(true);
//   const [email, setEmail] = useState('');
//   const [loading, setLoading] = useState(false); // To show loader when making the API call
//   const [error, setError] = useState(''); // For error messages

//   // Function to show OTP
//   const handleButtonClick = async () => {
//     if (!email) {
//       setError("Please enter your email.");
//       return;
//     }
    
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         "https://devfincenapi.rkllaw.com/auth/sendForgotLink",
//         { email },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
      
//       setLoading(false);
//       if (response.status === 200) {
//         // Email sent successfully
//         setShowotp(true);
//         setEmaildiv(false);
//         setError(''); // Clear any previous errors
//       } else {
//         // Handle error response
//         setError(response.data.message || "Failed to send OTP. Please try again.");
//       }
//     } catch (error) {
//       setLoading(false);
//       console.error("Error sending OTP link:", error);
//       setError("An error occurred. Please try again.");
//     }
//   };

//   const handleChange = (e, index) => {
//     const value = e.target.value;
//     if (/^[0-9]?$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);
      
//       // Move to the next input box if the current box is not empty
//       if (value !== '' && index < otp.length - 1) {
//         document.getElementById(`otp-${index + 1}`).focus();
//       }
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     alert(`Entered OTP is: ${otp.join('')}`);
//   };

//   return (
//     <div className="min-h-screen w-full flex flex-col bg-white">
//       <div className="fixed border-1 flex items-center border-black w-full bg-white px-6 py-4 justify-between border-b-2">
//         <Link to="/">
//           <img src={logo} alt="Logo" className="h-12 xs:w-28 sm:w-32 md:w-32 lg:w-32 xs:h-full" />
//         </Link>
//         <Link to="/">
//           <RxCross1 className="" />
//         </Link>
//       </div>

//       <div className="flex flex-grow flex-col xs:px-4 w-full xs:justify-start items-center justify-center pt-20">
//         {emaildiv && (
//           <div className='mt-4'>
//             <h2 className='text-[20px] lexend-medium'>For password change, enter your email</h2>
//             <input
//               type="text"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="w-full my-4 px-4 py-2 text-gray-700 placeholder-gray-500 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-600 focus:border-transparent"
//               placeholder="Email"
//               required
//             />
//             <button
//               onClick={handleButtonClick}
//               className='bg-[#4C00FF] text-white rounded-lg px-4 py-2'
//             >
//               {loading ? "Sending..." : "Submit"}
//             </button>
//             {error && <div className="text-red-500 mt-2">{error}</div>}
//           </div>
//         )}

//         {showotp && (
//           <div className='mt-4'>
//             <h2 className='text-[20px] lexend-medium'>Enter the OTP sent to your email</h2>
//             <form onSubmit={handleSubmit}>
//               <div className="flex space-x-2">
//                 {otp.map((digit, index) => (
//                   <input
//                     key={index}
//                     id={`otp-${index}`}
//                     type="text"
//                     value={digit}
//                     onChange={(e) => handleChange(e, index)}
//                     className="w-12 h-12 text-center text-lg border border-gray-300 rounded-md"
//                     maxLength="1"
//                   />
//                 ))}
//               </div>
//               <button
//                 type="submit"
//                 className='mt-4 bg-[#4C00FF] text-white rounded-lg px-4 py-2'
//               >
//                 Submit OTP
//               </button>
//             </form>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default OTPVerification;
