import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NoteState from './Components/Context/notes/NotesState';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NoteState>
    <App />
    </NoteState>
  </React.StrictMode>
);

// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
